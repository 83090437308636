<app-main-page-wrapper-no-border containerClass="four-o-four">
    <section class="fof-heading clear-fix-after">
        <div class="right-column">
            <h1>Sorry, page not found!</h1>
            <h2>The requested page does not exist on our site</h2>
        </div>
    </section>
    <section class="hero-container">
        <div class="hero"></div>

    </section>
    <section class="search-across-container">
        <search-across #searchAcross darkStyle="true" heading="Find a property for sale or to rent" subHeading="Search all the property websites..."
            saleRentButtonTypes="both" autoSearchOnEnter="true" placeholder="City, town or postcode"></search-across>
    </section>
</app-main-page-wrapper-no-border>